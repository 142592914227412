<template>
  <div>
    <v-row class="sticky-filters">
      <v-col

        cols="2"
      >
        <base-selector
          :items="statuses"
          label="Task status"
          :initial-value="filters.status"
          @select="selectStatus"
        />
      </v-col>

      <v-col
        cols="2"
      >
        <base-credentials-selector
          :initial-value="{_id: filters.credentials.value}"
          @select="selectCredentials"
        />
      </v-col>
      <v-col cols="2">
        <base-workflows-selector
          :initial-value="{_id: filters.workflow.value}"
          @select="selectWorkflows"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="5">
        <v-timeline clipped>
          <div
            v-for="(task, i) of tasksList"
            :key="task._id"
            @click="selectTask(i)"
          >
            <schedule-task-item
              :active="selectedTask._id === task._id"
              :task="task"
            />
          </div>
        </v-timeline>
      </v-col>
      <v-col cols="5">
        <prism-editor
          ref="editor"
          :value="selectedTaskForEditor"
          :readonly="true"
          class="my-editor"
          :highlight="highlighter"
        />
      </v-col>
      <v-col
        v-if="error"
        cols="6"
      >
        <v-alert
          type="error"
        >
          {{ error }}
        </v-alert>
      </v-col>
      <v-progress-linear
        v-if="loading"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      />
    </v-row>
  </div>
</template>

<script>
  import TasksPage from './TasksPage.vue';
  import { mapGetters } from 'vuex';
  import ScheduleTaskItem from '@/components/scheduleTasksPage/ScheduleTaskItem.vue';

  export default {
    name: 'ScheduleTasksPage',
    components: { ScheduleTaskItem },

    extends: TasksPage,
    data () {
      return {
        vuexFiltersAction: 'schedule/SET_FILTERS',
        selectedTaskForEditor: '{test: "kek"}',
        selectedTask: {},
        statuses: [
          { label: 'Task status', value: '' },
          { label: 'Done', value: 'done' },
          { label: 'Celery', value: 'celery' },
          { label: 'Failed', value: 'failed' },
          { label: 'Wait', value: 'wait' },
        ],
        error: '',
      };
    },
    computed: {
      ...mapGetters({
        tasksList: 'schedule/tasksList',
        filters: 'schedule/filters',
      }),
    },

    methods: {
      async loadTasks () {
        try {
          this.setQueryByFilters(this.filters);
          this.loading = true;

          await this.setTaskList();
        } catch (e) {
          console.error(e);
          this.error = e.message;
        } finally {
          this.loading = false;
        }
      },
      async setTaskList (count) {
        await this.$store.dispatch('schedule/LOAD_TASKS_LIST', count);
      },
    },

  };
</script>

<style>
  .my-editor {
    background: #2d2d2d;
    color: #ccc;

    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 25px;

    position: fixed;
    top: 150px;
    height: 75vh;
    width: 50vw;
    z-index: 1;
    white-space: pre-wrap;
  }

  .prism-editor__textarea:focus {
    outline: none;
  }
  .sticky-filters{
    position: sticky;
    top:60px;
    margin-left: 8px;
    background: #fff;
    z-index: 3;

  }
  .sticky-filters .col-2{
    max-width: 13%;
  }
</style>
